import React, { useEffect, useState } from "react";

// const

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

const Home = () => {
  const [activePage, setActivePage] = React.useState(1);
  const [value] = useState("2020-01-01");
  const [date, setDate] = useState("2020-01-01");

  // const [pages, setPages] = React.useState([]);
  // const images =[]
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };
  const handleThumbClick = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  const getSelectedDate = (date) => {
    // get selected year
    const selectedYear = date.getFullYear();
    // get selected month
    const selectedMonth = date.getMonth() + 1;
    // get selected day
    const selectedDay = date.getDate();
    setDate(
      selectedYear +
        "-" +
        (selectedMonth < 10 ? "0" + selectedMonth : selectedMonth) +
        "-" +
        (selectedDay < 10 ? "0" + selectedDay : selectedDay)
    );
  };

  // get the folder
  const getFolder = (date) => {
    return "./contents/" + date + "/bigPages/";
  };

  // console.log(getFolder(date));

  const [images, setImages] = useState([]);

  // loop through the folder and get the images
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImages = () => {
    const folder = getFolder(date);
    const images = [];
    for (let i = 1; i <= 20; i++) {
      images.push(folder + date + "_" + i + ".jpg");
    }
    setImages(images);
  };

  useEffect(() => {
    getImages();
  }, [date, getImages]);

  // console.log(images);
  const [open, setOpen] = React.useState(false);

  return (
    <div className="home">
      <div className="container">
        <div className="flex justify-between items-start gap-6 py-5">
          <aside className="left-sidebar w-1/5">
            {/* sidebar of the pages */}
            <div className="card border border-black">
              <div className="card-header bg-[#D32234] p-4 text-center">
                <h3 className="text-white font-bold text-xl">Main Menu</h3>
              </div>
              <div className="card-body stripped">
                {
                  <div className="pages flex flex-col">
                    {images.map((image, index) => (
                      <div
                        className={`page p-3 cursor-pointer hover:bg-[#D32234] hover:text-white ${
                          index === activePage - 1 && "active"
                        }`}
                        onClick={() => handleThumbClick(index + 1)}
                      >
                        Page {index + 1}
                      </div>
                    ))}
                  </div>
                }
              </div>
            </div>
          </aside>
          <main className="main bg-gray-100 p-2 w-3/5">
            <div className="flex justify-between items-center gap-4 mb-2">
              <button
                className="btn btn-primary flex justify-start items-center gap-2"
                onClick={() => handlePageChange(activePage - 1)}
                disabled={activePage === 1}
              >
                <FaChevronLeft /> Previous Page
              </button>
              <span>
                Date: {date} | Page: {activePage} of 20
              </span>
              <button
                className="btn btn-primary flex justify-end items-center gap-2"
                onClick={() => handlePageChange(activePage + 1)}
                disabled={activePage === 20}
              >
                Next page <FaChevronRight />
              </button>
            </div>
            <div className="img-holder min-h-[990px] bg-gray-100">
              {
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                  onClick={() => setOpen(true)}
                  src={images[activePage - 1]}
                  alt={`There is no Page No: ${activePage} on the date: ${date}`}
                  className="h-full w-full"
                />
              }
              {open && (
                <Lightbox
                  image={images[activePage - 1]}
                  title=""
                  onClose={() => {
                    setOpen(false);
                  }}
                ></Lightbox>
              )}
            </div>
          </main>
          <aside className="right-sidebar w-1/5">
            {/* calendar */}
            <Calendar onChange={getSelectedDate} value={value} />
          </aside>
        </div>
      </div>

      <div className="container-fluid p-3 grid grid-cols-20 gap-2 justify-between items-center">
        {images.map(
          (image, index) =>
            image !== "" && (
              <div
                className="relative w-1/20 h-[100px] cursor-pointer hover:opacity-50 hover:scale-105 transition-all duration-300 ease-in-out transform"
                key={index}
                onClick={() => handleThumbClick(index + 1)}
              >
                <span className="absolute top-0 left-0 z-20 w-full h-full flex justify-center items-center bg-[rgba(255,255,255,0.5)] text-xl font-bold">
                  {index + 1}
                </span>
                <img
                  src={image}
                  alt=""
                  className={`thumbImage ${
                    index === activePage - 1 && "active"
                  }`}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Home;
