import React from "react";
import logo from "../assets/img/helper-imgs/logo.png";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div>
      <div className="header border-b border-gray-300">
        <div className="container">
          <div className="text-center pt-2 pb-4">
            <img src={logo} className="logo h-[50px] mx-auto block" alt="" />
          </div>
        </div>
      </div>
      <Outlet />

      <div className="footer py-4 border-t border-gray-300">
        <div className="container">
          <div className="text-center">
            <p className="text-xs text-gray-400">
              © 2024 e-indepententbd. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
